import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "../styles/lifeAtVisiomate.scss"
import News from "../components/News"
import AboutHeader from "../components/AboutHeader"
import LAVFirst from "../components/LAVFirst"
import LAVSecond from "../components/LAVSecond"
import LAVThird from "../components/LAVThird"

const LifeAtVisiomate = ({ data }) => {
  return (
    <Layout data={data} pageTitle={"Visiomate - Life At Visiomate"}
    descrip = {"Discover the vibrant culture and values that make Visiomate a great place to work. Learn about our team, benefits, and commitment to employee growth."}>
      <AboutHeader isAbout={false} data={data} />
      <LAVFirst data={data} />
      <LAVSecond data={data} />
      <LAVThird data={data} />
      <News isAbout={true} />
    </Layout>
  )
}

export default LifeAtVisiomate;

export const query = graphql`
  query LifeAtVMQuery {
    AboutUs: file(relativePath: { eq: "about/22.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    WhyUs: file(relativePath: { eq: "about/23.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arrowImage: file(relativePath: { eq: "Mask group (5).png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arrowImageWhite: file(relativePath: { eq: "Mask group (6).png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutusbg: file(relativePath: { eq: "aboutusbg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomatebg: file(relativePath: { eq: "lifeatvisiomatebg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomateDiv2: file(relativePath: { eq: "lifeatvisiomate/good_environement3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomateDiv3: file(relativePath: { eq: "lifeatvisiomate/good_environement4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomateDiv4: file(relativePath: { eq: "lifeatvisiomate/vibrent_workplace.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomateDiv5: file(relativePath: { eq: "lifeatvisiomate/good_environement1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomateDiv6: file(relativePath: { eq: "lifeatvisiomate/visiomate_branding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomateDiv7: file(relativePath: { eq: "lifeatvisiomate/activity_area.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMThirdDiv2: file(relativePath: { eq: "lifeatvisiomate/UMT_Visiomate3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMThirdDiv3: file(relativePath: { eq: "lifeatvisiomate/UMT_Visiomate4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMThirdDiv4: file(relativePath: { eq: "lifeatvisiomate/UMT_Visiomate.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMThirdDiv5: file(relativePath: { eq: "lifeatvisiomate/UMT_Visiomate2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMTSecondDiv1: file(relativePath: { eq: "lifeatvisiomate/cricket_event1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMTSecondDiv3: file(relativePath: { eq: "lifeatvisiomate/cricket_event.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMTSecondDiv4: file(relativePath: { eq: "lifeatvisiomate/cricket_event3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMTSecondDiv5: file(relativePath: { eq: "lifeatvisiomate/dinner.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LVMTSecondDiv6: file(relativePath: { eq: "lifeatvisiomate/trip.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`